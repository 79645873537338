// @flow strict
import {useState} from "react";
import * as firebase from "firebase/app";
import {useAuthState} from 'react-firebase-hooks/auth';

import {
    User,
    getAuth,
    signInWithEmailAndPassword,
    sendEmailVerification,
    createUserWithEmailAndPassword,
    signOut,
    AuthError,
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence
} from "firebase/auth";
import {useNavigate} from "react-router-dom";

const app = firebase.initializeApp({
    apiKey: "AIzaSyDOZgPAq1eddhdjRpXcfn26VqHqWmzrNtc",
    authDomain: "vpnblocker.firebaseapp.com"
});

export type ReturnAuthType = {
    // $FlowFixMe
    user: ?User,
    loading: boolean,
    // $FlowFixMe
    error: ?AuthError,
    login: (email: string, password: string, remember: boolean) => void,
    loginError: ?string,
    register: (email: string, password: string, confirmPassword: string, agree: boolean) => void,
    registerError: ?string,
    logout: () => void
}
/**
 * @returns {{logout: logout, loginError: null, loading: boolean, error: Error, login: login, user: User}}
 */
export const useAuth = (): ReturnAuthType => {
    const auth = getAuth(app);

    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [loginError, setLoginError] = useState(null);
    const [registerError, setRegisterError] = useState(null);

    const login = (email: string, password: string, remember: boolean) => {
        setPersistence(auth, remember ? browserLocalPersistence : browserSessionPersistence);
        signInWithEmailAndPassword(auth, email, password).then(() => {
            navigate('/');
        }).catch((error) => {
            switch (error.code) {
                case 'auth/user-not-found':
                    setLoginError('No user found with the provided email.')
                    break;
                case 'auth/invalid-email':
                    setLoginError('Invalid email address.')
                    break;
                case 'auth/wrong-password':
                    setLoginError('Invalid email or password combination.')
                    break;
                case 'auth/too-many-requests':
                    setLoginError('Too many requests, try again later.')
                    break;
                default:
                    setLoginError(error.code)
            }
        });
    };

    const register = (email: string, password: string, confirmPassword: string, agree: boolean) => {
        if (!password) {
            setRegisterError('You must enter a valid password.');
            return;
        }
        if (password !== confirmPassword) {
            setRegisterError('Passwords do not match.');
            return;
        }

        if (!agree) {
            setRegisterError('You must agree to the terms and privacy policy.');
            return;
        }
        createUserWithEmailAndPassword(auth, email, password).then((cred) => {
            sendEmailVerification(cred.user)
            signOut(auth);
        }).catch((error) => {
            switch (error.code) {
                case 'auth/missing-email':
                    setRegisterError('You must enter an email address.');
                    break;
                case 'auth/invalid-email':
                    setRegisterError('Invalid email address.')
                    break;
                case 'auth/weak-password':
                    setRegisterError('You must enter a stronger password.')
                    break;
                case 'auth/email-already-in-use':
                    setRegisterError('Email is already registered.');
                    break;
                case 'auth/too-many-requests':
                    setRegisterError('Too many requests, try again later.')
                    break;
                default:
                    setRegisterError(error.code);
            }
        });
    };

    const logout = () => {
        signOut(auth).then(() => {
            navigate("/");
        });
    };

    return {
        user,
        loading,
        error,
        login,
        loginError,
        register,
        registerError,
        logout,
    }
}
