// @flow strict
import React from "react";
import {useAuth} from "../hooks/useAuth";

const Logout = (): React$Node => {
    const {logout} = useAuth();

    logout();

    return <p>Logging out...</p>
};

export default Logout;
