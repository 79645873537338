// @flow strict
import React from "react";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// $FlowFixMe
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Logout from "./pages/Logout";
import {theme} from "./theme";
import {ThemeProvider} from '@mui/material/styles';
import Register from "./pages/Register";

const App = (): React$Node => {

    return <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Dashboard/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register />} />
                <Route path="/logout" element={<Logout/>}/>
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
}

export default App;
