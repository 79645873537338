// @flow strict
import {useEffect, useState} from "react";
import type {User} from "../api/fetchUser";
import fetchUser from "../api/fetchUser";
import {useAuth} from "./useAuth";

export type ReturnUserType = {
    isLoading: boolean,
    apiUser: ?User
}

/**
 * @returns {{isLoading: boolean, apiUser: null}}
 */
const useUser = (): ReturnUserType => {

    const {user} = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [apiUser, setApiUser] = useState<?User>(null);


    useEffect(() => {
        if (user) {
            fetchUser(user).then((user: User) => {
                setApiUser(user)
                setIsLoading(false);
            })
        }
    }, [user]);

    return {
        isLoading,
        apiUser
    }
}

export default useUser;
