// @flow strict

export type FirebaseUser = {
    getIdToken: () => Promise<string>
}
export const getApiServer = (): string => {
    return process.env.REACT_APP_API_HOST ?? 'https://api.vpnblocker.net'
};

export const query = (
    user: FirebaseUser,
    path: string
): Promise<string> => {
    return user.getIdToken().then(token => {
        return fetch(getApiServer() + path, {
            headers: {
                "Authorization": token
            }
        }).then(result => {
            return result.json();
        });
    })
}
