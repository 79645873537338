import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#c71f1f',
            light: '#d42821',
            dark: '#9d1b1d',
        },
        secondary: {
            main: '#7496c4',
        },
    },
});
