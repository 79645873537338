// @flow strict
import React, {useEffect} from "react";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import useUser from "../hooks/useUser";

const Dashboard = (): React$Node => {
    const {user, loading, error} = useAuth();
    const {isLoading, apiUser} = useUser();

    const navigate = useNavigate();

    useEffect(() => {
        if (!user && !loading && !error) {
            navigate("/login");
        }
    }, [user, loading, error, navigate])

    if (loading) {
        return (
            <div>
                <p>Initialising User...</p>
            </div>
        );
    }
    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
            </div>
        );
    }
    if (user) {
        return (
            <div>
                <p>Secure dashboard</p>
                <p>Current User: {user.email}</p>
                {isLoading ? <p>Loading...</p> : <p>API User: {apiUser ? apiUser.email : 'none'}</p>}
                <p>Verified: {user.emailVerified ? 'verified': 'not-verified'}</p>
            </div>
        );
    }

    return null;
};

export default Dashboard;
