// @flow strict
import {type FirebaseUser, query} from "./server";

export type User = {
    email: string
}

/**
 * @param user
 * @returns {Promise<{email: *} | User>}
 */
const fetchUser = (user: FirebaseUser): Promise<User> => {
    return query(user, '/account/user').then((json: any) => {
        return {
            'email': json["email"]
        }
    })
}

export default fetchUser;